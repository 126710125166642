<template>
  <div class="marque__textContent" data-aos="fade-up" data-aos-delay="200">
    <div class="marque__headingContainer">
      <!-- <div class="marque__circle"></div> -->
      <h1
        :class="headingclass"
        class="marque__heading text-uppercase"
        v-html="heading"
      ></h1>
      <p
        v-if="subheading"
        class="marque__text subheading"
        :class="text1class"
        v-html="subheading"
      ></p>
    </div>
    <div class="marque__unique" v-if="logo">
      <img :src="logo" alt="" />
    </div>
    <p v-if="text1" class="marque__text" :class="text1class" v-html="text1"></p>
    <p v-if="text2" class="marque__text" :class="text2class" v-html="text2"></p>
    <p v-if="text3" class="marque__text" :class="text2class" v-html="text3"></p>
  </div>
</template>

<script>
export default {
  props: [
    "heading",
    "text1",
    "text2",
    "text3",
    "logo",
    "headingclass",
    "text1class",
    "text2class",
    "subheading",
  ],
};
</script>

<style lang="scss" scoped></style>

<template>
  <div
    class="marque__section3 "
    :class="locale"
    data-aos="marque-s3"
    data-aos-delay="100"
    data-aos-anchor-placement="top-center"
    id="marqueSection3"
  >
    <div
      class="row mx-0 flex-column-reverse flex-md-row justify-content-between w-100 align-items-center marque__section3__textContent"
      :class="locale"
    >
      <div class="col-md-8 px-0">
        <div class="w-100">
          <TextContent
            :text1class="`marque__section3__text1 ${locale}`"
            :text2class="`marque__section3__text2 ${locale}`"
            :headingclass="`marque__section3__heading ${locale}`"
            :heading="$t('marque.section3.heading')"
            :text1="$t('marque.section3.text1')"
            :text2="$t('marque.section3.text2')"
          />
        </div>
      </div>
      <div
        class="col-md-4 px-0 marque__section3__bottlesContainer text-md-right text-center"
      >
        <img class="img-fluid " :src="rightimg" alt="image" />
      </div>
    </div>
  </div>
</template>

<script>
import TextContent from "./TextContent.vue";
export default {
  components: {
    TextContent,
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  data() {
    return {
      rightimg: require("../../assets/images/marque/section3img.png"),
    };
  },
};
</script>

<style lang="scss" scoped></style>

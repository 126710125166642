<template>
  <div
    ref="circlesmenu"
    class="circle-animated"
    v-click-outside="onClickOutside"
  >
    <div class="circleboxContainer">
      <div class="circlebox" :class="{ show: showCircles }"></div>
      <div class="circlebox" :class="{ show: showCircles }"></div>
      <div class="circlebox" :class="{ show: showCircles }"></div>
    </div>
    <div class="circles" @click="showCircles = !showCircles">
      <img class="img-fluid"  width="60" :src="circle" alt="circles" />
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      circle: require("../../assets/images/home/section1/circle.png"),
      showCircles: false,
    };
  },
  methods: {
    onClickOutside(event) {
      this.showCircles = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div
    class="marque__section1 position-relative "
    :class="locale"
    id="marqueSection1"
  >
    <img
      class="img-fluid marque__section1__whitebottle"
      :class="locale"
      :src="whitebottle"
      alt="white bottle"
    />
    <!-- fr version -->
    <div v-if="locale == 'fr'" class="row mx-0  w-100  h-100 ">
      <div class="col-md-4 col-12  px-0">
        <div
          class="position-relative marque__section1__imagesContainer"
          :class="locale"
        >
          <img
            class="img-fluid marque__section1__leftImg"
            :src="bottle"
            alt="bottle"
          />
          <div class="marque__section1__mobileHeadingContainer" :class="locale">
            <h1
              class="d-md-none d-block text-white marque__section1__mobileHeadingContainer__heading"
            >
              {{ $t("marque.section1.sm_heading") }}
            </h1>
          </div>
        </div>
      </div>
      <div class="col-md-8 col-12 px-0 marque__section1__col2Container d-flex ">
        <div class="w-100 marque__section1__textcontent" :class="locale">
          <TextContent
            v-if="locale == 'fr'"
            :headingclass="
              `marque__section1__heading d-md-block d-none ${locale}`
            "
            :heading="$t('marque.section1.heading')"
            :text1="$t('marque.section1.text1')"
            :text2="$t('marque.section1.text2')"
            :text1class="`marque__section1__text1 ${locale}`"
            :text2class="`marque__section1__text2 ${locale}`"
          />

          <div class="d-flex align-items-center pt-4  justify-content-start">
            <div class="circle1  mr-xl-5 mr-lg-4 mr-2 ml-0">
              <img :src="circle1" alt="" />
            </div>
            <div class="circle2 mx-xl-5 mx-lg-5 mx-3">
              <img :src="circle2" alt="" />
            </div>
            <div class="circle3  mx-xl-5 ml-md-2 mx-lg-3 mr-5   ">
              <img :src="circle3" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ar version -->
    <div v-else-if="locale == 'ar'" class="row mx-0  w-100  h-100 ">
      <div class="col-lg-4 col-12  px-0">
        <div
          class="position-relative marque__section1__imagesContainer"
          :class="locale"
        >
          <img
            class="img-fluid marque__section1__leftImg"
            :src="bottle"
            alt="bottle"
          />
          <div class="marque__section1__mobileHeadingContainer" :class="locale">
            <h1
              class="d-md-none d-block text-white marque__section1__mobileHeadingContainer__heading"
              :class="locale"
            >
              {{ $t("marque.section1.sm_heading") }}
            </h1>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-12 px-0 marque__section1__col2Container d-flex ">
        <div class="w-100 marque__section1__textcontent" :class="locale">
          <TextContent
            :headingclass="
              `marque__section1__heading d-lg-block d-none ${locale}`
            "
            :heading="$t('marque.section1.heading')"
            :subheading="$t('marque.section1.subheading')"
            :text1="$t('marque.section1.text1')"
            :text2="$t('marque.section1.text2')"
            :text3="$t('marque.section1.text3')"
            :text1class="`marque__section1__text1 ${locale}`"
            :text2class="`marque__section1__text2 ${locale}`"
          />
          <div class="d-flex align-items-center pt-4  justify-content-start">
            <div class="circle1  mr-xl-5 mr-lg-4 mr-2 ml-0" :class="locale">
              <img :src="circle1" alt="" />
            </div>
            <div class="circle2 mx-xl-5 mx-lg-5 mx-3" :class="locale">
              <img :src="circle2" alt="" />
            </div>
            <div
              class="circle3  mx-xl-5 ml-md-2 mx-lg-3 mr-5   "
              :class="locale"
            >
              <img :src="circle3" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextContent from "./TextContent.vue";
export default {
  components: {
    TextContent,
  },
  data() {
    return {
      circle1: this.$i18n.locale === 'ar' ? require("../../assets/images/marque/circle1_ar.jpg") : require("../../assets/images/marque/circle1.png"),
      circle2: require("../../assets/images/marque/circle2.png"),
      circle3: require("../../assets/images/marque/circle3.png"),
      bottle: this.$i18n.locale === 'ar' ? require("../../assets/images/marque/section1img_ar.jpg") : require("../../assets/images/marque/section1img.webp"),
      whitebottle: require("../../assets/images/marque/whitebottle.png"),
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped></style>

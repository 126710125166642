<template>
  <!-- waves -->
  <div class="wavesContainer" :class="locale">
    <img :src="waves" alt="waves" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      waves: require("../../assets/images/home/common/waves.svg"),
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped></style>

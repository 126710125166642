<template>
  <div class="marque">
    <AppHeader />
    <full-page ref="fullpage2" :options="options" id="fullpage2">
      <Section1 class="section " />
      <Section2 class="section" />
      <Section3 class="section" />
      <AppFooter
        @onClick="moveup"
        class="section fp-auto-height footer-section marque-footer"
        :class="locale"
      />
    </full-page>
    <CircleMenu />
    <Waves />
  </div>
</template>

<script>
import Section1 from "@/components/Marque/section1.vue";
import Section2 from "@/components/Marque/section2.vue";
import Section3 from "@/components/Marque/section3.vue";
import Waves from "@/components/general/Waves.vue";
import CircleMenu from "@/components/general/Circles.vue";
import AppHeader from "@/components/Header.vue";
import AppFooter from "../components/footer/Footer.vue";
export default {
  data() {
    return {
      options: {
        licenseKey: "BD497841-800F4E51-B6A2DCEB-DE65B139",
        scrollingSpeed: 1000,
        scrollBar: true,

        responsiveWidth: 991,
      },
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  components: {
    CircleMenu,
    Section1,
    Section2,
    Section3,
    Waves,
    AppHeader,
    AppFooter,
  },
  methods: {
    moveup() {
      this.$scrollTo("#marqueSection1", 500);
    },
    isInViewport(el, offset = 0) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom - offset <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    onScroll() {
      let circleClass = document.querySelector(".circle-animated");
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        circleClass.classList.add("hide");
      } else {
        circleClass.classList.remove("hide");
      }
    },
  },
  mounted() {
    if (this.$route.hash) {
      this.$scrollTo(this.$route.hash, 500);
    }

    document.addEventListener("scroll", this.onScroll, {
      passive: true,
    });
  },
  destroyed() {
    document.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style lang="scss" scoped></style>

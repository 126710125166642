<template>
  <div
    class="marque__section2"
    data-aos="marque-s2"
    data-aos-delay="100"
    data-aos-anchor-placement="top-center"
    id="marqueSection2"
  >
    <div
      data-aos="fade-up"
      data-aos-delay="100"
      class="text-center d-flex justify-content-center align-items-center "
    >
      <img class="img-fluid" :src="bottles" width="1000px" alt="bottles" />
    </div>
    <!-- <div class="text-center d-block d-md-none marque__section2__shapedBottles">
      <img class="img-fluid" :src="section2img" alt="Bottle shaped images" />
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      bottles: this.$i18n.locale === "ar"
          ? require("../../assets/images/marque/bottles_ar.png")
          : require("../../assets/images/marque/bottles.svg"),
      // bottles: require("../../assets/images/marque/bottles.svg"),
      // section2img: require("../../assets/images/marque/section2img.png"),
    };
  },
};
</script>

<style lang="scss" scoped></style>

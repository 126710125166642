import { render, staticRenderFns } from "./Waves.vue?vue&type=template&id=b0fa1d7e&scoped=true&"
import script from "./Waves.vue?vue&type=script&lang=js&"
export * from "./Waves.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0fa1d7e",
  null
  
)

export default component.exports